import React from "react"
import styled from "styled-components"
import { parseSize } from "../utils/parseSize"
import { colors } from "../utils/siteVars"
import Button from "./Button"

const StyledFile = styled.div`
  margin-bottom: 1rem;
  p.filename {
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
    position: relative;
    /* &::before {
      position: absolute;
      content: "";
      background: linear-gradient(
        to right,
        rgba(237, 237, 237, 0),
        rgba(237, 237, 237, 1)
      );
      width: 10px;
      right: 0;
      height: 100%;
      top: 0;
      bottom: 0;
    } */
  }
`

const File = ({ file, download }) => {
  return (
    <StyledFile className="files-grid">
      <div className="files-grid-texts">
        <p className="m0 filename">{file.name}</p>
        <p className="m0 center">{parseSize(file.size)}</p>
        <p className="m0 center">{file.type.toUpperCase()}</p>
      </div>
      <Button small circle className="icon">
        <img src="/icons/arrow-down.svg" alt="download" />
      </Button>
      <Button small className="text" onClick={download}>
        Ladda ned
      </Button>
    </StyledFile>
  )
}

export default File
