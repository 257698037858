import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import File from "../components/File"
import { breakpoints, do_url } from "../utils/siteVars"
import Button from "../components/Button"
import TwoCol from "../components/TwoCol"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"
import axios from "axios"
import { navigate } from "gatsby-link"

const StyledFilesPage = styled.div`
  h2 {
    display: flex;
    img {
      margin-right: 1rem;
      width: 2rem;
    }
  }
  .files-container {
    display: flex;
    flex-direction: column;
    .files-grid {
      display: grid;
      align-items: center;
      grid-template-columns: 6fr 10rem;
      &-texts {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr;
        gap: 1rem;
      }
      &.center {
        text-align: center;
      }
      column-gap: 1rem;
    }
    .files-headers {
      /* margin-right: 4rem; */
      p {
        margin: 0;
        font-weight: 400;
      }
    }
    button.icon {
      display: none;
    }
    .download-all {
      margin-left: auto;
    }
  }
  ${breakpoints.mobile} {
    h2 {
      flex-direction: column;
      align-items: center;
      img {
        margin: 0;
        margin-bottom: 1rem;
        width: 2rem;
      }
    }
    .files-container {
      .files-headers {
        display: none;
        p.center {
          display: none;
        }
      }
      .files-grid {
        grid-template-columns: 1fr 2rem;
        &-texts {
          grid-template-columns: auto 1fr;
          p.filename {
            grid-column: 1 / 3;
          }
          p.center {
            margin-right: 1rem;
            opacity: 0.5;
            font-size: 15px;
            &:nth-last-child(1) {
              position: relative;
              &::before {
                position: absolute;
                content: "";
                background: rgba(0, 0, 0, 0.5);
                height: 60%;
                width: 1px;
                top: 20%;
                left: -0.5rem;
                bottom: 0;
              }
            }
          }
        }
        button.text {
          display: none;
        }
        button.icon {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          width: 2rem;
          img {
            width: 80%;
            height: 80%;
            object-fit: contain;
            margin: 0;
          }
        }
      }
    }
  }
`

const FilesPage = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [files, setFiles] = useState([])
  const [filesLoaded, setFilesLoaded] = useState(false)

  const getFiles = () => {
    dispatch({ type: "LOADING", value: false })
    axios
      .get(`${do_url}${state.token}/files-list`)
      .then(res => {
        dispatch({ type: "LOADING", value: false })
        setFiles(res.data)
        setFilesLoaded(true)
        // console.log(res.data)
      })
      .catch(err => {
        console.log(err.response)
        dispatch({ type: "ALERT", content: "Kunde inte hämta filer." })
      })
  }

  const downloadFile = ({ filename }) => {
    dispatch({ type: "LOADING", value: true })
    window.open(`${do_url}${state.token}/single-file/${filename}`)
    dispatch({ type: "LOADING", value: false })
    // axios
    //   .get(`${do_url}${state.token}/single-file/${filename}`)
    //   .then(res => {
    //     axios
    //       .post(
    //         `${process.env.GATSBY_API}yes-auth/set-downloaded-file-date`,
    //         {},
    //         {
    //           headers: { Authorization: "Bearer " + state.token },
    //         }
    //       )
    //       .catch(e => console.log(e))
    //     console.log(res)
    //     const arrayBufferView = new Uint8Array(res.data)
    //     const blob = new Blob([arrayBufferView])
    //     const urlCreator = window.URL || window.webkitURL
    //     const url = urlCreator.createObjectURL(blob)
    //     const a = document.createElement("a")
    //     a.href = url
    //     a.download = filename
    //     a.click()
    //     dispatch({ type: "LOADING", value: false })
    //   })
    //   .catch(err => {
    //     console.log(err.response)
    //     dispatch({ type: "ALERT", content: "Något gick fel" })
    //     dispatch({ type: "LOADING", value: false })
    //   })
  }

  const downloadAllFiles = () => {
    dispatch({ type: "LOADING", value: true })
    const URL = `${do_url}${state.token}/all-files`
    window.open(URL)
    axios
      .post(
        `${process.env.GATSBY_API}yes-auth/set-downloaded-file-date`,
        {},
        {
          headers: { Authorization: "Bearer " + state.token },
        }
      )
      .then(() => {
        dispatch({ type: "LOADING", value: false })
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    dispatch({ type: "LOADING", value: true })
    if (state.token) {
      getFiles()
    } else {
      dispatch({ type: "LOADING", value: false })
      navigate("/")
    }
    // eslint-disable-next-line
  }, [state.token])

  return (
    <Layout>
      <StyledFilesPage>
        <TwoCol className="content-width" template="2fr 3fr">
          <div className="left-content">
            <h2 className="m0">
              <span>
                <img src="/icons/document.svg" alt="" />
              </span>
              Ladda ned filer
            </h2>
            <p className="light">
              Här kan du ladda ned dina filer.
              <br />
              Observera att filerna raderas automatiskt från detta utrymme efter
              14 dagar.
            </p>
          </div>
          {filesLoaded && (
            <div className="files-container">
              <div className="files-headers files-grid">
                <div className="files-grid-texts">
                  <p className="light">Filnamn</p>
                  <p className="center light">Storlek</p>
                  <p className="center light">Filtyp</p>
                </div>
              </div>
              <div className="hr mtop1 mbottom1" />
              <div className="files-container-inner">
                {files.map((file, i) => (
                  <File
                    download={() => downloadFile(file)}
                    key={file.filename + i}
                    file={{
                      name: file.filename,
                      size: file.filesize,
                      type: file.filetype,
                    }}
                  />
                ))}
              </div>
              <div className="hr mbottom2 mtop1" />
              <Button med className="download-all" onClick={downloadAllFiles}>
                Ladda ned alla filer
              </Button>
            </div>
          )}
        </TwoCol>
      </StyledFilesPage>
    </Layout>
  )
}

export default FilesPage
